<template>
  <div class="relatorio-dre-init">
    <v-container fluid>
    <h1 class="titulo">Relatório DRE</h1>
      <v-row justify="center">
        <v-col  cols="12" class="justify-center center-itens-download">
          <v-row class="justify-center">
            <v-col dense cols="4">
              <v-row>
                <v-col dense cols="6" sm="6" class="pb-0">
                  <autoCompleteEntities
                    :entityProps.sync="entityStart"
                    :typesEntities="['2']"
                    :label="'Supervisor Inicial'"
                    :error-messages="entityStartIErrors" />
                </v-col>
                <v-col dense cols="6" sm="6" class="pb-0">
                  <autoCompleteEntities
                    :entityProps.sync="entityEnd"
                    :typesEntities="['2']"
                    :label="'Supervisor Final'"
                    :error-messages="entityEndIErrors" />
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="4" class="d-flex align-center">
              <!-- <v-btn class="bottom"  color="info" justify-self="center" @click="buscarRelatorio(true)" outlined>Buscar</v-btn> -->
              <strong class="mr-2">Período: </strong>
              <FiltroRangeDate
                class="mr-3"
                @input="filtrar"
                :value="rangeDate"
                :labelBtn="'Selecionar Data'"
                :actionLabels="{ apply: 'OK' }"
              />

              <v-btn class="bottom"  color="info" justify-self="center" @click="buscarRelatorio(true)" outlined>Buscar</v-btn>

              <div @mouseleave="visible = false" class="bottom-mobile">
                <v-btn class="bottom-2" color="info" @mouseover="visible = true"> Baixar </v-btn>
                <v-list :class="[!visible? 'none' : 'visivel']">
                  <template v-for="(item, index) in items">
                    <v-list-item :key="index" @click="downalod(item.value)">
                      <v-list-item-title>{{ item.title }} </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-list>
              </div>
            </v-col>
          </v-row>
        </v-col>
      <!-- Botoes para download -->
        <v-col cols="12" class="relatorio">
          <div class="relatorio tex-center">
            <!-- <div class="relatorio-pagination">
                <span  @click="incrementPage('-')"> <v-icon>keyboard_arrow_left</v-icon></span>
                <span> Página {{ page  }} de {{ pageMax }} </span>
                <span class="icon-right"  :disabled="page === pageMax" @click="incrementPage('+')">  <v-icon>keyboard_arrow_right</v-icon> </span>
            </div> -->
          </div>
        </v-col>
        <v-col cols="12" class="conteudo-div">
          <v-skeleton-loader :loading="loader" type="table" >
            <div v-html="dados"></div>

          </v-skeleton-loader>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import moment from 'moment'
import { validationMixin } from 'vuelidate'
import { requiredIf } from 'vuelidate/lib/validators'

import { errorSnackbar } from '@/core/service/utils'

// mixins para com funcao padroes na geração de relatorios
import mixinRelatorio from '@/views/relatorios/mixins/relatorio'

export default {
  name: 'RelatorioDRE',
  mixins: [mixinRelatorio, validationMixin],
  components: {
    FiltroRangeDate: () => import('@/views/components/filtroRangeDate'),
    autoCompleteEntities: () => import('@/views/components/autoCompleteEntities/entitiesDRE'),
  },
  data: () => ({ 
    request: null,
     rangeDate: {
      start: '',
      end: ''
    },

    entityStart: '',
    entityEnd: ''
  }),
  mounted () {
    // const vm = this
    this.request = setInterval(() => {
      this.buscarRelatorio({ page: this.page, pageSize: this.$store.getters.ItensPaginacao,  _addlFilter: this.filter ? this.filter : undefined }).then(() => this.loadingTable = false)
    }, 60 * 60 * 1000)
    console.log(this.request)

    this.rangeDate = {
      start: moment(this.form.dtInicial, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD'),
      end:  moment(this.form.dtFinal, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD')
    }
  },
  beforeDestroy() {
    clearInterval(this.request)
  },
  computed: {
    // ...mapState({ dados: state => state.relatorioGanhadoresAdmin.conteudo }),
    ...mapGetters('entities', ['listEntitiesDre']),
    ...mapState('DRE', {
      dados: 'conteudo'
    }),

    entityStartIErrors () {
      const errors = []
      if (!this.$v.entityStart.$dirty) return errors
      !this.$v.entityStart.required && errors.push('Campo obrigatório')
      return errors
    },

    entityEndIErrors () {
      const errors = []
      if (!this.$v.entityEnd.$dirty) return errors
      !this.$v.entityEnd.required && errors.push('Campo obrigatório')
      return errors
    },
  },
  methods: {
    ...mapActions('DRE', ['getHtml', 'requestRelatorio']),
    init () {},

    buscarRelatorio (clear = false) {
      if (this.$v.$invalid) { 
        this.$v.$touch()
        return false 
      }

      const vm = this
      if (clear) {
        vm.page = 1
      }
      vm.loaderPaginacao = true
      this.loader = true

      const arrParentPath = [ ...this.listEntitiesDre.map(item => item.id) ]
      let parentPath      
      if (this.entityStart && this.entityEnd) {
        parentPath = arrParentPath.slice(
          arrParentPath.indexOf(this.entityStart.value),
          arrParentPath.indexOf(this.entityEnd.value) + 1
        )
      }
      vm.getHtml({tipo: 'html', ...vm.form, parentPath, page: vm.page, ...vm.sendDates}).then(() => { vm.loader = false })
        .then(() => {
          setTimeout(() => {
            vm.formatStyleRelatorio()
            vm.setUrlImages()
          }, 500)
        })
        .catch(() => {
          errorSnackbar('Não possui relatório')
        })
        .finally(() => this.loader = false)

    },

    filtrar (e) {
      if (!(moment(e.start).isSameOrAfter(moment('2021-04-01')))) {
        this.$swal({
          icon: 'error',
          text: `Data de inicio inválida, por favor selecionar uma data maior ou igual a 01/04/2021`,
          showConfirmButton: false,
          showCancelButton: false,
        })
        return
      }

      if (e.start !== '' && e.end !== '') {
        // this.loader = true
        this.form.dtInicial = moment(e.start).format('DD/MM/YYYY 00:00:00')
        this.form.dtFinal =  moment(e.end).format('DD/MM/YYYY 23:59:59')
        // this.buscarRelatorio(true)
      } else {
        // this.loadingPag = true
        // this.getItens({ page: this.page, pageSize: this.$store.getters.ItensPaginacao,  order: { id: 'desc' } })
        //      .finally(() => this.loadingPag = false)
      }
      this.rangeDate = {
        start: e.start,
        end: e.end
      }
    }
  },

  validations () {
    return  {
      entityStart: { required: requiredIf(() => this.entityEnd) },
      entityEnd: { required: requiredIf(() => this.entityStart) },
    }
  }
}
</script>

<style lang="scss" >
$cor: #0091ff;
.relatorio-dre-init{
  .none{
    display: none;
  }
  .conteudo-div{
    padding-top:0 ;
    > table{
      background: white !important;
    }
    @media(max-width: 1320px) {
      table{
        width: 1290px;
      }
      overflow-x: scroll;
    }
  }
  .relatorio {
    padding-top: 0;
    padding-bottom: 0;
    position: relative;
    width: 100%;
    display: flex;
    align-items: 'center';
    justify-content: 'center';
    &-download {
      width: 100%;
      &-icons {
        text-align: right;
        width: 40px;
        color: $cor ;
        background:#fafafa;
        padding: 5px;
        display: inline-block;
        border-radius: 5px;
        margin-left: 5px;
        border: 1px solid $cor;
        font-size: 0.8rem;
        font-weight: 600;
        cursor: pointer;
        &:hover{
          background:$cor ;
          opacity: 0.8;
          color: white;
        }
        &:first-child{
          margin-right: 5px;
        }
      }
    }
    &-pagination{
      position: relative;
      display: flex;
      justify-content: flex-end;
      @media(max-width: 660px) {
        justify-content: center;
      }
      align-items: center;
      flex-wrap: nowrap;
      width: 100%;
      span {
        display: flex;
        &:last-child {
          cursor: pointer;
        }
        &:nth-child(2) {
          justify-content: center;
          align-items: center;
        }
        &:first-child {
          cursor: pointer;
          justify-content: flex-end;
        }
      }
    }

  }
  .default-colunas {
    margin-bottom: 0;
    padding-bottom: 0;
    padding-top: 0;
  }
  .center-itens{
    &-download {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      // @extend .default-colunas;
    }
    &-datas {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      // @extend .default-colunas;
    }
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    @extend .default-colunas;
    > div {
      padding: 0;
    }
  }
  .v-list {
    position: absolute;
    border: 1px solid $cor;
    background: #fafafa;
    z-index: 1;
    width: 72px;
    @media(max-width: 769px){
      right: 25px;
    }
    .v-list-item{
      min-height: 30px;
      &__title{
        font-size: 12px;
        font-weight: 600;
      }
      &:hover  {
        color: $cor !important;
      }
    }
  }
  .bottom{
    margin-right: 5px;
    box-shadow: none !important;
    &-2{
      background-color: transparent !important;
      border: 1px solid $cor;
      color: $cor !important;
      box-shadow: none !important;
    }
    &-mobile {
      @media(max-width: 769px) {
      width: 100%;
      text-align: right;
      }
    }
  }
}
</style>